<template>
  <v-container>
    <v-card class="mx-auto" max-width="750" tile>
      <v-card-title class="gradients white--text text-capitalize">
        {{ $t("my profile") }}
        <v-spacer></v-spacer>
        <v-btn
          text
          class="picked"
          dark
          small
          :loading="loading"
          elevation="3"
          @click="editProfile($store.state.currentUser)"
        >
          <span class="font-weight-bold">{{ $t("edit") }}</span>

          <v-icon small>edit</v-icon>
        </v-btn>
      </v-card-title>
      <v-container class="px-7">
        <v-layout row wrap>
          <v-flex xs12 sm12 md3 lg3>
            <v-avatar class="profile" color="white" size="164" tile>
              <v-img :src="user.avatar" contain></v-img>
            </v-avatar>
          </v-flex>
          <v-flex xs12 sm12 md9 lg9>
            <v-layout row wrap>
              <v-flex xs12 sm12 md12 lg12 class="my-2">
                <span class="text-h4 font-weight-bold">{{
                  getFullNames(user.names)
                }}</span>
              </v-flex>
            </v-layout>

            <v-layout row wrap class="my-1">
              <v-flex xs12 sm12 md6 lg6 class="my-1">
                <span class="subtitle-1 font-weight-bold">
                  {{ $t("gender") }}:
                </span>
                <span class="subtitle-2 font-weight-regular">{{
                  user.gender
                }}</span>
              </v-flex>

              <v-flex xs12 sm12 md6 lg6 class="my-1">
                <span class="subtitle-1 font-weight-bold"
                  >{{ $t("email") }}:
                </span>
                <span class="subtitle-2 font-weight-regular">{{
                  user.email
                }}</span>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>

            <v-layout row wrap class="my-1">
              <v-flex xs12 sm12 md6 lg6 class="my-1">
                <span class="subtitle-1 font-weight-bold"
                  >{{ $t("telephone") }}:
                </span>
                <span class="subtitle-2 font-weight-regular">{{
                  user.phone
                }}</span>
              </v-flex>

              <v-flex xs12 sm12 md6 lg6 class="my-1">
                <span class="subtitle-1 font-weight-bold"
                  >{{ $t("PO BOX") }}:
                </span>
                <span class="subtitle-2 font-weight-regular">{{
                  user.address.pobox
                }}</span>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>

            <v-layout row wrap class="my-1">
              <v-flex xs12 sm12 md6 lg6 class="my-1">
                <span class="subtitle-1 font-weight-bold">
                  {{ $t("country") }}:
                </span>
                <span class="subtitle-2 font-weight-regular">{{
                  user.address.country
                }}</span>
              </v-flex>

              <v-flex xs12 sm12 md6 lg6 class="my-1">
                <span class="subtitle-1 font-weight-bold"
                  >{{ $t("county") }}:
                </span>
                <span class="subtitle-2 font-weight-regular">{{
                  user.address.county
                }}</span>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>

            <v-layout row wrap class="my-1">
              <v-flex xs12 sm12 md6 lg6 class="my-1">
                <span class="subtitle-1 font-weight-bold">
                  {{ $t("city") }}:
                </span>
                <span class="subtitle-2 font-weight-regular">{{
                  user.address.city
                }}</span>
              </v-flex>

              <v-flex xs12 sm12 md6 lg6 class="my-1">
                <span class="subtitle-1 font-weight-bold"
                  >{{ $t("street") }}:
                </span>
                <span class="subtitle-2 font-weight-regular">{{
                  user.address.street
                }}</span>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>

            <v-layout row wrap class="my-1">
              <v-flex xs12 sm12 md6 lg6 class="my-1">
                <span class="subtitle-1 font-weight-bold"
                  >{{ $t("estate") }}:
                </span>
                <span class="subtitle-2 font-weight-regular">{{
                  user.address.estate
                }}</span>
              </v-flex>

              <v-flex xs12 sm12 md6 lg6 class="my-1">
                <span class="subtitle-1 font-weight-bold"
                  >{{ $t("building") }}:
                </span>
                <span class="subtitle-2 font-weight-regular">{{
                  user.address.building
                }}</span>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>

            <v-layout row wrap class="my-1">
              <v-flex xs12 sm12 md6 lg6 class="my-1">
                <span class="subtitle-1 font-weight-bold">
                  {{ $t("house-suite-number") }} # :
                </span>
                <span class="subtitle-2 font-weight-regular">{{
                  user.address.houseNo
                }}</span>
              </v-flex>
            </v-layout>
            <v-divider></v-divider>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card>
  </v-container>
</template>

<script>
import jwtdecode from "jwt-decode";

export default {
  data: () => ({
    user: {
      names: "Doe John Smith",
      email: "jane@doe.com",
      gender: "M",
      phone: "254723465768",
      address: {
        building: "Odeon Cinema",
        city: "Nairobi",
        country: "Kenya",
        county: "Nairobi",
        estate: "Kamukunji",
        houseNo: "15",
        pobox: "23904",
        street: "Tom Mboya",
      },
    },
  }),

  created() {
    this.getUser();
  },

  methods: {
    async getUser() {
      this.user = jwtdecode(
        this.$store.state.token,
        this.$store.state.accessKey
      );
    },

    editProfile(uId) {
      this.$router.push({ name: "EditProfile", params: { id: uId } });
    },

    getFullNames(names) {
      return names.first + " " + names.middle.substr(0, 1) + ". " + names.last;
    },
  },
};
</script>

<style></style>
